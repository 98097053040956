import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { login } from '../actions/auth';
import LoginForm from '../components/login/login-form';
import arrowLeft from '../assets/arrow-left.svg';
import { ACTIONS } from '../constants/auth';

const LoginPage = (_, context) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmitHandler = (data) => {
    dispatch(login(data));
  };

  const { loggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    if (loggedIn) {
      history.push('/details');
      dispatch({ type: ACTIONS.LOGIN_FAILURE, payload: null });
    }

    return () => {
      dispatch({ type: ACTIONS.LOGIN_FAILURE });
    };
  }, [loggedIn, history, dispatch]);

  return (
    <div className="col p-0 m-0">
      <div className="container">
        <div className="row flex-d justify-content-center my-5">
          <h3 className="border-right pr-4 font-weight-bold text-right">
            <Link onClick={() => history.push('/register')} className="text-muted">
              {context.t('APP.REGISTER')}
            </Link>
          </h3>

          <h3 className="pl-4 font-weight-bold text-dark text-left">{context.t('APP.LOGIN')}</h3>
        </div>

        <h3 className=" font-weight-bold text-center">{context.t('APP.LOGIN.TITLE')}</h3>
        <h6 className="text-center mb-5">{context.t('APP.LOGIN.DESC')}</h6>

        <LoginForm onSubmit={onSubmitHandler} />
      </div>

      <div className="d-none d-sm-block space-5" />

      <div className="row">
        <div className="col-md border py-5 d-flex flex-column align-items-center justify-content-center">
          <div className="d-none d-sm-block space-5" />

          <h1 className=" font-weight-bold text-center w-75 m-0">
            {context.t('APP.FOOTER.MIOO_SAFETY_TITLE_1')}
          </h1>

          <h4 className="text-center pt-5">
            <a href={context.t('APP.FOOTER.MIOO_SAFETY_LINK_1')} className="text-dark">
              {context.t('APP.FOOTER.MIOO_SAFETY_DESC_1')}
              <img src={arrowLeft} alt="→" />
            </a>
          </h4>

          <div className="d-none d-sm-block space-5" />
        </div>

        <div className="col-md border py-5 d-flex flex-column align-items-center justify-content-center">
          <div className="d-none d-sm-block space-5" />

          <h1 className=" font-weight-bold text-center w-75 m-0">
            {context.t('APP.FOOTER.MIOO_SAFETY_TITLE_2')}
          </h1>

          <div className="d-none d-sm-block space-5" />
        </div>
      </div>
    </div>
  );
};

LoginPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default LoginPage;

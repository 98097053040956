import { ACTIONS } from '../constants/insurance';
import * as InsuranceAPI from '../api/insurance';

export const insuranceDetails = (data) => (dispatch) => {
  dispatch({ type: ACTIONS.INSURANCE_DETAILS_START, payload: data.ssf_bicylce_marking === true });

  InsuranceAPI.insuranceDetails(data)
    .then((response) => {
      dispatch({ type: ACTIONS.INSURANCE_DETAILS_SUCCESS, payload: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: ACTIONS.INSURANCE_DETAILS_FAILURE, payload: err.response.data.error });
    });
};

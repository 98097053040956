import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useLocation, withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import I18n from 'redux-i18n';
import ReactPixel from 'react-facebook-pixel';

import Store from './store';
import { translations } from './translations';
import initilizeHttpClient from './config/axios-config';
import config from './config/config';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import CreditCardPage from './pages/checkout-page';
import ConfirmationPage from './pages/confirmation-page';
import DetailsPage from './pages/details-page';
import LoginPage from './pages/login-page';
import RegisterPage from './pages/register-page';
import ForgotPasswordPage from './pages/forgot-password-page';

const store = Store();
const lang = localStorage.getItem('lang') || config.defaultLanguage;

initilizeHttpClient();

const _ScrollToTop = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return props.children;
};

const options = {
  autoConfig: true,
  debug: false,
};

ReactPixel.init('526090285080195', null, options);
ReactPixel.pageView();

const ScrollToTop = withRouter(_ScrollToTop);

function App() {
  return (
    <Provider store={store}>
      <I18n translations={translations} initialLang={lang} fallbackLang="en">
        <div className="app">
          <Header />
          <div className="app-body">
            <BrowserRouter>
              <ScrollToTop>
                <Switch>
                  <Route exact path="/">
                    <Redirect to="/register" />
                  </Route>

                  <Route path="/details">
                    <DetailsPage />
                  </Route>

                  <Route path="/checkout">
                    <CreditCardPage />
                  </Route>

                  <Route path="/confirmation">
                    <ConfirmationPage />
                  </Route>

                  <Route path="/login">
                    <LoginPage />
                  </Route>

                  <Route path="/register">
                    <RegisterPage />
                  </Route>
                  <Route path="/forgot-password">
                    <ForgotPasswordPage />
                  </Route>
                </Switch>
              </ScrollToTop>
            </BrowserRouter>
          </div>
          <Footer />
        </div>
      </I18n>
    </Provider>
  );
}

export default App;

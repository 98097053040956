import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Form } from 'reactstrap';
import LaddaButton from 'react-ladda';
import { useSelector } from 'react-redux';

import { InputField } from '../../helpers/form/render';
import { requiredField, emailField } from '../../helpers/form/validator';

const ForgotPasswordForm = ({ handleSubmit }, context) => {
  const { loading } = useSelector((state) => state.auth);
  return (
    <div className="my-5">
      <Form onSubmit={handleSubmit}>
        <Field
          name="email"
          type="email"
          label={context.t('APP.REGISTER.EMAIL')}
          component={InputField}
          className="login-form-width"
        />
        <div className="row flex-d justify-content-center align-items-center">
          <LaddaButton
            className="btn btn-primary btn-ladda mt-4 mb-1 mx-2 login-form-width"
            loading={loading}
          >
            {context.t('APP.RESET')}
          </LaddaButton>
        </div>
      </Form>
    </div>
  );
};

const forgotPasswordFormValidator = (formProps) => {
  const errors = {};
  requiredField(formProps, errors, 'email', 'VALIDATION.EMAIL.ERROR_REQUIRED');
  emailField(formProps, errors, 'email', 'VALIDATION.EMAIL.WRONG_FORMAT');
  return errors;
};

ForgotPasswordForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'ForgotPasswordForm',
  validate: forgotPasswordFormValidator,
})(ForgotPasswordForm);

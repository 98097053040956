import React from 'react';
import { Form } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import LaddaButton from 'react-ladda';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { InputField } from '../../helpers/form/render';
import { requiredField, compareField, emailField, minLength } from '../../helpers/form/validator';

const RegisterForm = ({ handleSubmit }, context) => {
  const { loading, error } = useSelector((state) => state.auth);
  const history = useHistory();

  return (
    <div className="my-5">
      <Form onSubmit={handleSubmit}>
        <Field
          name="first_name"
          type="text"
          component={InputField}
          className="login-form-width"
          label={context.t('APP.DETAILS.FIRST_NAME')}
        />

        <Field
          name="last_name"
          type="text"
          component={InputField}
          className="login-form-width"
          label={context.t('APP.DETAILS.LAST_NAME')}
        />

        <Field
          name="email"
          type="email"
          className="login-form-width"
          component={InputField}
          label={context.t('APP.REGISTER.EMAIL')}
        />

        <Field
          name="phone_number"
          type="tel"
          component={InputField}
          className="login-form-width"
          label={context.t('APP.DETAILS.PHONE_NUMBER')}
        />

        <Field
          name="password"
          type="password"
          component={InputField}
          className="login-form-width"
          label={context.t('APP.REGISTER.PASSWORD')}
        />

        <Field
          name="repeat_password"
          type="password"
          component={InputField}
          className="login-form-width"
          label={context.t('APP.REGISTER.REPEAT_PASSWORD')}
        />

        <div className="row flex-d justify-content-center align-items-center">
          <LaddaButton
            className="btn btn-primary btn-ladda mt-4 mb-1 login-form-width"
            loading={loading}
          >
            {context.t('APP.REGISTER.BUTTON')}
          </LaddaButton>
        </div>

        <div className="row flex-d justify-content-center">
          <div className="column text-center">
            <p className="mt-2">
              Har du ett MIOO konto?{' '}
              <span className="pointer" onClick={() => history.push('/login')}>
                Logga in.
              </span>
            </p>
            {error && <h7 class="text-danger text-center">{context.t(error)}</h7>}
          </div>
        </div>
      </Form>
    </div>
  );
};

RegisterForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const loginFormValidator = (formProps) => {
  const errors = {};

  requiredField(formProps, errors, 'email', 'VALIDATION.EMAIL.ERROR_REQUIRED');
  requiredField(formProps, errors, 'password', 'VALIDATION.PASSWORD.ERROR_REQUIRED');

  emailField(formProps, errors, 'email', 'VALIDATION.EMAIL.ERROR_INVALID');
  minLength(formProps, errors, 'password', 3, 'VALIDATION.PASSWORD.ERROR_SHORT');
  compareField(
    formProps,
    errors,
    'repeat_password',
    'password',
    'VALIDATION.PASSWORD.ERROR_NOT_EQUAL'
  );

  return errors;
};

export default reduxForm({
  form: 'RegisterForm',
  validate: loginFormValidator,
})(RegisterForm);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormGroup, Col, FormText } from 'reactstrap';

class RadioField extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const {
      meta: { touched, error, invalid, warning },
    } = this.props;

    return (
      <FormGroup row className={`${touched && invalid ? 'has-error' : ''} mr-3 mb-0`}>
        <Col md="12">
          <FormText color="danger" className="help-block">
            {touched &&
              ((error && <span>{this.context.t(error)}</span>) ||
                (warning && <span>{this.context.t(warning)}</span>))}
          </FormText>
        </Col>
      </FormGroup>
    );
  }
}

export default connect((state) => ({
  lang: state.i18nState.lang,
}))(RadioField);

import React from 'react';
import PropTypes from 'prop-types';

import omocom from '../../assets/omocom.png';

const Footer = (_, context) => {
  return (
    <div className="row m-0">
      <div className="col-md-7 footer-text d-flex align-items-center justify-content-center text-center px-5 footer-box">
        <span>{context.t('APP.FOOTER.TEXT')}</span>
      </div>

      <div className="col d-flex align-items-center justify-content-center footer-box">
        <img src={omocom} alt="Omocom" style={{ height: 64 }} />
      </div>
      {/* <div className="col d-flex align-items-center justify-content-center footer-box">
        <img src={ssf} alt="SSF" style={{ height: 54 }} />
      </div> */}
    </div>
  );
};

Footer.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Footer;

import validator from 'validator';

// Helpers
export const propertyInObject = (obj, property) => {
  if (obj[property]) {
    return true;
  }
  return false;
};

export const requiredField = (formValues, errorObj, field, message) => {
  const error = errorObj;
  if (!propertyInObject(formValues, field)) {
    error[field] = message;
  }
  return error;
};

export const emailField = (formValues, errorObj, field, message) => {
  const error = errorObj;
  if (formValues[field] && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues[field])) {
    error[field] = message;
  }
  return error;
};

export const changeField = (formProps, field, value) => {
  formProps.dispatch(formProps.change(field, value));
};

export const onlyNumber = (limit) => {
  return (value, previousValue) => {
    if (!limit) {
      return validator.isNumeric(value) || value === '' ? value : previousValue;
    }
    return (value.length <= limit && validator.isNumeric(value)) || value === ''
      ? value
      : previousValue;
  };
};

export const numberField = (formValues, errorObj, field, message) => {
  const error = errorObj;
  if (!propertyInObject(formValues, field)) {
    error[field] = message;
    return error;
  }

  const parsed = parseInt(formValues[field], 10);
  if (isNaN(parsed)) {
    error[field] = message;
    return error;
  } else {
    formValues[field] = parsed;
  }

  return error;
};

export const minValue = (formValues, errorObj, field, min, message) => {
  const error = errorObj;
  if (parseInt(formValues[field], 10) < parseInt(min, 10)) {
    error[field] = message;
  }
  return error;
};

export const minLength = (formValues, errorObj, field, min, message) => {
  const error = errorObj;
  if (formValues[field] !== undefined && formValues[field].length < min) {
    error[field] = message;
  }
  return error;
};

export const maxLength = (formValues, errorObj, field, max, message) => {
  const error = errorObj;
  if (formValues[field] !== undefined && formValues[field].length > max) {
    error[field] = message;
  }
  return error;
};

export const compareField = (formValues, errorObj, field, field1, message) => {
  const error = errorObj;
  if (formValues[field] !== formValues[field1]) {
    error[field] = message;
  }
  return error;
};

export const isBetween = (formValues, errorObj, field, min, max, message) => {
  const error = errorObj;
  if (!propertyInObject(formValues, field)) {
    error[field] = message;
    return error;
  }

  if (
    parseInt(formValues[field], 10) < parseInt(min, 10) ||
    parseInt(formValues[field], 10) > parseInt(max, 10)
  ) {
    error[field] = message;
  }

  return error;
};

export const equals = (formValues, errorObj, field, value, message) => {
  const error = errorObj;
  if (formValues[field] !== value) {
    error[field] = message;
  }
  return error;
};

import React from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import LaddaButton from 'react-ladda/dist/LaddaButton';
import { SLIDE_DOWN } from 'react-ladda/dist/constants';
import { Field, reduxForm } from 'redux-form';
import { Form, FormGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { InputField } from '../../helpers/form/render';
import { requiredField } from '../../helpers/form/validator';
import { ACTIONS, SUBSCRIPTION_ERRORS } from '../../constants/subscription';
import { handleStripeError } from '../../helpers/helpers';

const CARD_OPTIONS = {
  style: {
    base: {
      fontSize: '14px',
      color: '##3e515b',
      '::placeholder': {
        color: '#AAB7C4',
      },
    },
    invalid: {
      color: '#FF5454',
      iconColor: '#FF5454',
    },
  },
};

const CheckoutForm = ({ stripe, elements, clientSecret, onSubmit }, context) => {
  const dispatch = useDispatch();

  const { loading, error } = useSelector((state) => state.subscription);
  const name = useSelector((state) => state.form.CheckoutForm?.values?.name_on_card);
  const promoCode = useSelector((state) => state.form.CheckoutForm?.values?.promo_code);

  const handleSubmit = async (event) => {
    // Loading start
    dispatch({ type: ACTIONS.SUBSCRIPTION_START });

    // Block native form submission.
    event.preventDefault();

    // Get stripe element
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    // Get stripe token
    const card = await stripe.createToken(cardNumberElement, {
      type: 'card',
      billing_details: {
        name: name,
      },
    });

    if (card.error) {
      const message = handleStripeError(card.error, SUBSCRIPTION_ERRORS);

      dispatch({ type: ACTIONS.SUBSCRIPTION_FAILURE, payload: message });
    } else {
      onSubmit(card.token.id, promoCode, cardNumberElement, stripe);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label check className="control-label mb-2">
          {context.t('APP.CHECKOUT.CARD_INFO')}
        </Label>
        <CardNumberElement options={CARD_OPTIONS} />
        <div className="row mt-4">
          <div className="col">
            <CardExpiryElement options={CARD_OPTIONS} />
          </div>
          <div className="col">
            <CardCvcElement options={CARD_OPTIONS} />
          </div>
        </div>
      </FormGroup>

      <FormGroup>
        <Field
          className="w-100"
          name="name_on_card"
          type="text"
          component={InputField}
          label={context.t('APP.CHECKOUT.NAME_ON_CARD')}
        />
      </FormGroup>

      <FormGroup>
        <Field
          className="w-100"
          name="promo_code"
          type="text"
          component={InputField}
          label={context.t('APP.CHECKOUT.PROMO_CODE')}
        />
      </FormGroup>

      <LaddaButton
        className="btn btn-primary w-100 btn-lg mt-4 mb-1"
        data-style={SLIDE_DOWN}
        loading={loading}
      >
        {context.t('APP.CHECKOUT.BUTTON')}
      </LaddaButton>
      <div className="text-center">{error && <h7 class="text-danger">{context.t(error)}</h7>}</div>
    </Form>
  );
};

CheckoutForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const checkoutFormValidator = (formProps) => {
  const errors = {};

  requiredField(formProps, errors, 'name_on_card', 'VALIDATION.ADDRESS.ERROR_REQUIRED');

  return errors;
};

export default reduxForm({
  form: 'CheckoutForm',
  validate: checkoutFormValidator,
})(CheckoutForm);

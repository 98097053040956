// local configuration object
const local = {
  GOOGLE_CLIENT_ID: '',
  //API_BASE_URL: 'http://localhost:8000/',
  API_BASE_URL: 'https://dev-api.mioo.cc/',
  MIOO_OPERATION_CENTER: { lat: 59.332054, lng: 18.067424 },
  MIOO_OPERATION_RADIUS: 3700,
  SSF_PRODUCT_ID: 2,
  stripe_key: 'pk_test_TfXXWPOi4mlUGeTYgFJB13On',
};

// dev environment
const development = {
  GOOGLE_CLIENT_ID: '',
  API_BASE_URL: 'https://dev-api.mioo.cc/',
  MIOO_OPERATION_CENTER: { lat: 59.332054, lng: 18.067424 },
  MIOO_OPERATION_RADIUS: 3700,
  SSF_PRODUCT_ID: 2,
  stripe_key: 'pk_test_TfXXWPOi4mlUGeTYgFJB13On',
};

// staging environment
const staging = {
  GOOGLE_CLIENT_ID: '',
  API_BASE_URL: 'https://stg-api.mioo.cc/',
  MIOO_OPERATION_CENTER: { lat: 43.855069, lng: 18.399384 },
  MIOO_OPERATION_RADIUS: 3700,
};

const production = {
  GOOGLE_CLIENT_ID: '',
  API_BASE_URL: 'https://api-v2.mioo.cc/',
  MIOO_OPERATION_CENTER: { lat: 59.332054, lng: 18.067424 },
  MIOO_OPERATION_RADIUS: 3700,
  SSF_PRODUCT_ID: 2,
  stripe_key: 'pk_live_QyukB0r7Bu4MafwS21BHuYmc',
};

const config =
  process.env.REACT_APP_BUILD_TARGET === 'PRODUCTION'
    ? production
    : process.env.REACT_APP_BUILD_TARGET === 'STAGING'
    ? staging
    : process.env.REACT_APP_BUILD_TARGET === 'DEVELOPMENT'
    ? development
    : local;

export default {
  source: 'web-insurance',
  defaultLanguage: 'sv',
  languages: [
    { code: 'en', title: 'APP.LANG.ENGLISH', flag: 'United-Kingdom' },
    { code: 'sv', title: 'APP.LANG.SWEDISH', flag: 'Sweden' },
  ],

  ...config,
};

import { ACTIONS } from '../constants/subscription';

const INITIAL_STATE = {
  subscribeSuccess: false,
  insurances: [],
  oneOfs: [],
  error: null,
  loading: false,
};

export const subscription = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.SUBSCRIPTION_START:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case ACTIONS.SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscribeSuccess: true,
        error: null,
        loading: false,
      };

    case ACTIONS.SUBSCRIPTION_FAILURE:
      return {
        ...state,
        subscribeSuccess: false,
        error: action.payload,
        loading: false,
      };

    case ACTIONS.INSURANCES_START:
      return {
        ...state,
        loading: true,
        error: null,
        insurances: [],
      };

    case ACTIONS.INSURANCES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        insurances: action.payload.subscription,
        oneOfs: action.payload.one_time,
      };

    case ACTIONS.INSURANCES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        insurances: [],
      };

    default:
      return state;
  }
};

export const ACTIONS = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  SUBSCRIPTION_START: 'SUBSCRIPTION_START',
  SUBSCRIPTION_SUCCESS: 'SUBSCRIPTION_SUCCESS',
  SUBSCRIPTION_FAILURE: 'SUBSCRIPTION_FAILURE',

  INSURANCES_START: 'INSURANCES_START',
  INSURANCES_SUCCESS: 'INSURANCES_SUCCESS',
  INSURANCES_FAILURE: 'INSURANCES_FAILURE',
};

export const SUBSCRIPTION_ERRORS = {
  incomplete_number: 'APP.SUBSCRIPTION.CARD_NUMBER_REQUIRED',
  invalid_number: 'APP.SUBSCRIPTION.CARD_NUMBER_REQUIRED',
  incomplete_expiry: 'APP.SUBSCRIPTION.CARD_EXPIRATION_REQUIRED',
  invalid_expiry_year_past: 'APP.SUBSCRIPTION.CARD_EXPIRATION_REQUIRED',
  invalid_expiry_month_past: 'APP.SUBSCRIPTION.CARD_EXPIRATION_REQUIRED',
  incomplete_cvc: 'APP.SUBSCRIPTION.CARD_CVC_REQUIRED',
  user_is_subscribed_already: 'APP.SUBSCRIPTION.USER_ALREADY_SUBSCRIBED',
};

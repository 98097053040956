export const handleApiError = (error, errors = {}, skipReturnError = false) => {
  // in the future here we might add here sending error
  // towards some external service like Sentry for logging errors

  if (!skipReturnError) {
    const errorCause = error?.response?.data?.error?.message;
    return errors[errorCause] ? errors[errorCause] : 'APP.COMMON.DEFAULT_ERROR';
  }
};

export const handleStripeError = (error, errors = {}, skipReturnError = false) => {
  // in the future here we might add here sending error
  // towards some external service like Sentry for logging errors

  if (!skipReturnError) {
    const errorCause = error?.code;
    return errors[errorCause] ? errors[errorCause] : 'APP.COMMON.DEFAULT_ERROR';
  }
};

export const ACTIONS = {
  REGISTER: 'REGISTER',
  REGISTER_START: 'REGISTER_START',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',

  LOGIN: 'LOGIN',
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  FORGOT_PASSWORD_START: 'FORGOT_PASSWORD_START',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
};

export const REGISTER_ERRORS = {
  user_exists: 'APP.REGISTER.USER_EXISTS',
};

export const LOGIN_ERRORS = {
  wrong_password: 'APP.LOGIN.WRONG_CREDENTIALS',
  user_does_not_exist: 'APP.LOGIN.WRONG_CREDENTIALS',
};

import React from 'react';
import PropTypes from 'prop-types';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

const returnYears = () => {
  let years = [];
  for (let i = moment().year() - 100; i <= moment().year() + 50; i++) {
    years.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }
  return years;
};

const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <div className="form-group">
      <select
        className="form-control mr-1"
        value={month.month()}
        style={{ marginTop: -5 }}
        onChange={(e) => onMonthSelect(month, e.target.value)}
      >
        {moment.months().map((label, value) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
    <div>
      <select
        className="form-control"
        style={{ marginTop: -5 }}
        value={month.year()}
        onChange={(e) => onYearSelect(month, e.target.value)}
      >
        {returnYears()}
      </select>
    </div>
  </div>
);

const DateInput = ({ input, label, labelMeta, meta, isOutsideRange }, context) => {
  return (
    <div className="form-group">
      {label && labelMeta && (
        <div className="row">
          <div className="col">
            <label>{label}</label>
          </div>
          <div className="col-auto">{labelMeta}</div>
        </div>
      )}
      {label && !labelMeta && <label>{label}</label>}

      <SingleDatePicker
        id={input.name}
        displayFormat={() => 'DD/MM/YYYY'}
        numberOfMonths={1}
        isOutsideRange={isOutsideRange ? isOutsideRange : () => false}
        date={input.value}
        focused={meta.active}
        onDateChange={(value) => input.onChange({ value })}
        onFocusChange={({ focused }) => (focused ? input.onFocus(true) : input.onBlur(true))}
        renderMonthElement={renderMonthElement}
      />
      <div className="invalid-feedback d-block">
        {meta.touched &&
          ((meta.error && <span>{context.t(meta.error)}</span>) ||
            (meta.warning && <span>{context.t(meta.warning)}</span>))}
      </div>
    </div>
  );
};

DateInput.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default DateInput;

import React from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import LaddaButton from 'react-ladda';
import PropTypes from 'prop-types';

import { ErrorField, InputField } from '../../helpers/form/render';
import { requiredField, equals } from '../../helpers/form/validator';
import priceInputField from '../../helpers/form/render/price-input-field';

const DetailsForm = ({ handleSubmit }, context) => {
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <FormGroup className="m-0" row>
          <Field
            className="details-form-width mr-5"
            name="social_security_number"
            type="text"
            component={InputField}
            label={context.t('APP.DETAILS.SOCIAL_SECURITY_NUMBER')}
          />

          <Field
            className="details-form-width mr-5"
            name="address"
            type="text"
            component={InputField}
            label={context.t('APP.DETAILS.ADDRESS')}
          />
        </FormGroup>
        <FormGroup row className="m-0">
          <Field
            className="details-form-width mr-5"
            name="sol_number"
            type="text"
            component={InputField}
            label={context.t('APP.DETAILS.SOL_NUMBER')}
          />

          <Field
            className="details-form-width mr-5"
            name="post_code"
            type="number"
            component={InputField}
            label={context.t('APP.DETAILS.POST_CODE')}
          />
        </FormGroup>

        <Field
          className="mr-5"
          name="price"
          type="number"
          component={priceInputField}
          label={context.t('APP.DETAILS.PRICE')}
        />

        <FormGroup row className="m-0">
          <Field
            className="details-form-width mr-5"
            name="purchase_date"
            type="date"
            component={InputField}
            label={context.t('APP.DETAILS.PURCHASE_DATE')}
          />
        </FormGroup>

        <FormGroup row className="m-0" tag="fieldset">
          <FormGroup check>
            <Label check className="control-label mr-5 d-flex flex-row align-items-center ">
              <Field
                name="bicycle_type"
                component="input"
                type="radio"
                className="form-check-input mt-0"
                value="normal_bicylce"
              />
              {context.t('APP.DETAILS.NORMAL_BICYCLE')}
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label check className="control-label mr-3 d-flex flex-row align-items-center ">
              <Field
                name="bicycle_type"
                component="input"
                type="radio"
                className="form-check-input mt-0"
                value="electirc_bicycle"
              />
              {context.t('APP.DETAILS.ELECTRIC_BICYCLE')}
            </Label>
          </FormGroup>
        </FormGroup>

        <FormGroup check>
          <Field name="bicycle_type" component={ErrorField} />
        </FormGroup>

        <FormGroup check className="mt-3">
          <Label check className="control-label mr-3">
            <Field name="TERMS" component="input" type="checkbox" className="form-check-input" />
            {context.t('APP.DETAILS.TERMS')}{' '}
            <a
              href=" https://mk0mioocycling2xvh8f.kinstacdn.com/wp-content/uploads/2021/03/Forsakringsvillkor.pdf"
              target="_blank"
            >
              {context.t('APP.DETAILS.TERMS_LINK')}
            </a>
          </Label>

          <Field name="TERMS" component={ErrorField} />
        </FormGroup>

        <p className="mt-2 text-dark">
          ** För att försäkringen skall vara gilltig måste du fylla i fälten om din cykel. Om du
          inte har tillgängligt information om din cykel just nu, kan du skicka in den i efterhand
          till MIOOs support på hello@mioo.cc
        </p>

        <LaddaButton className="btn btn-primary btn-ladda mt-5">
          {context.t('APP.DETAILS.BUTTON')}
        </LaddaButton>
      </Form>
    </div>
  );
};

DetailsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const detailsFormValidator = (formProps) => {
  const errors = {};

  requiredField(
    formProps,
    errors,
    'social_security_number',
    'VALIDATION.SOCIAL_SECURITY_NUMBER.ERROR_REQUIRED'
  );
  requiredField(formProps, errors, 'address', 'VALIDATION.ADDRESS.ERROR_REQUIRED');
  requiredField(formProps, errors, 'first_name', 'VALIDATION.FIRST_NAME.ERROR_REQUIRED');
  requiredField(formProps, errors, 'last_name', 'VALIDATION.LAST_NAME.ERROR_REQUIRED');
  requiredField(formProps, errors, 'post_code', 'VALIDATION.POST_CODE.ERROR_REQUIRED');
  requiredField(formProps, errors, 'phone_number', 'VALIDATION.PHONE_NUMBER.ERROR_REQUIRED');
  requiredField(formProps, errors, 'bicycle_type', 'VALIDATION.BICYCLE_TYPE.ERROR_REQUIRED');
  equals(formProps, errors, 'TERMS', true, 'VALIDATION.TERMS.ERROR_REQUIRED');

  return errors;
};

export default reduxForm({
  form: 'DetailsForm',
  validate: detailsFormValidator,
})(DetailsForm);

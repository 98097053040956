import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import CheckoutForm from '../components/checkout/checkout-form';
import config from '../config/config';
import * as Actions from '../actions/subscription';
import { ACTIONS } from '../constants/subscription';

const stripePromise = loadStripe(config.stripe_key);

const CheckoutPage = (props, context) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { ssf } = useSelector((state) => state.insurance);
  const { subscribeSuccess, insurances, oneOfs } = useSelector((state) => state.subscription);

  useEffect(() => {
    if (subscribeSuccess) history.push('/confirmation');

    dispatch({ type: ACTIONS.SUBSCRIPTION_FAILURE, payload: null });
  }, [subscribeSuccess, history, dispatch]);

  useEffect(() => {
    dispatch(Actions.insurances());

    return () => {
      dispatch({ type: ACTIONS.SUBSCRIPTION_FAILURE });
    };
  }, [dispatch]);

  const onSubmitHandler = (tokenId, promoCode, cardElement, stripe) => {
    if (insurances.length > 0)
      dispatch(
        Actions.subscribe(
          tokenId,
          insurances[0].id,
          promoCode,
          oneOfs[0].id,
          ssf,
          stripe,
          cardElement
        )
      );
  };

  return (
    <div className="vw-100">
      <div className="container">
        <div className="row mt-5 d-flex justify-content-center align-items-center">
          <div className="col-6 text-center">
            <h5>Månadskostnad</h5>
            <h1 className="font-weight-bold">{ssf ? 'SEK 328.00' : 'SEK 29.00'}</h1>
          </div>

          <div className="col mb-5">
            <h3 className="my-4">{context.t('APP.CHECKOUT.PAY_WITH_CARD')}</h3>

            <Elements stripe={stripePromise}>
              <ElementsConsumer>
                {({ elements, stripe }) => {
                  return (
                    <CheckoutForm elements={elements} stripe={stripe} onSubmit={onSubmitHandler} />
                  );
                }}
              </ElementsConsumer>
            </Elements>
          </div>
        </div>
      </div>

      <div className="d-none d-sm-block space-5" />
      <div className="border-top"></div>

      <div className="container d-flex align-items-center justify-content-center">
        <div className="column d-flex flex-column justify-content-center align-items-center py-5 px-4">
          <div className="d-none d-sm-block space-5" />
          <h1 className="font-weight-bold mb-5 text-center">{context.t('APP.CHECKOUT.TITLE')}</h1>

          <h4 className="text-center">{context.t('APP.CHECKOUT.DESC')}</h4>
          <div className="d-none d-sm-block space-5" />
        </div>
      </div>
    </div>
  );
};

CheckoutPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CheckoutPage;

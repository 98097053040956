import axios from 'axios';

export const register = (data) => {
  return axios({
    method: 'POST',
    url: 'account/register',
    data: data,
  });
};

export const login = (data) => {
  return axios({
    method: 'POST',
    url: 'account/login',
    data: data,
  });
};

export const forgotPassword = (email) => {
  return axios({
    method: 'POST',
    url: 'account/forgot-password',
    data: { email },
  });
};

import ReactPixel from 'react-facebook-pixel';

import * as SubscriptionsAPI from '../api/subscription';
import { ACTIONS, SUBSCRIPTION_ERRORS } from '../constants/subscription';
import { handleApiError } from '../helpers/helpers';

export const subscribe = (token, plan, promoCode, oneOf, ssf, stripe, cardElement) => (
  dispatch
) => {
  dispatch({ type: ACTIONS.SUBSCRIPTION_START });

  SubscriptionsAPI.subscribe(token, plan, promoCode)
    .then((response) => {
      const payment_intent = response.data.data.subscription?.latest_invoice?.payment_intent;
      const setup_intent = response.data.data.subscription?.pending_setup_intent;

      if (
        (payment_intent?.next_action?.type ?? setup_intent?.next_action?.type) === 'use_stripe_sdk'
      ) {
        (payment_intent !== null
          ? stripe.confirmCardPayment(payment_intent.client_secret, {
              payment_method: { card: cardElement },
            })
          : stripe.confirmCardSetup(setup_intent.client_secret, {
              payment_method: { card: cardElement },
            })
        ).then((res) => {
          if (res.error) {
            dispatch({ type: ACTIONS.SUBSCRIPTION_FAILURE, payload: res.error });
          } else {
            dispatch({ type: ACTIONS.SUBSCRIPTION_SUCCESS });
            ReactPixel.trackCustom('insurance-purchased', response.data.data);
          }
        });
      } else {
        dispatch({ type: ACTIONS.SUBSCRIPTION_SUCCESS });
        ReactPixel.trackCustom('insurance-purchased', response.data.data);
      }
    })
    .catch((err) => {
      const message = handleApiError(err, SUBSCRIPTION_ERRORS);

      dispatch({ type: ACTIONS.SUBSCRIPTION_FAILURE, payload: message });
    });
};

export const insurances = () => (dispatch) => {
  dispatch({ type: ACTIONS.INSURANCES_START });

  SubscriptionsAPI.insurances()
    .then((response) => {
      dispatch({ type: ACTIONS.INSURANCES_SUCCESS, payload: response.data.data });
    })
    .catch((err) => {
      dispatch({ type: ACTIONS.INSURANCES_FAILURE, payload: err.response.data.error });
    });
};

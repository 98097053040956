import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { i18nState } from 'redux-i18n';

import { auth } from './auth';
import { subscription } from './subscription';
import { insurance } from './insurance';

const reducers = combineReducers({
  form: formReducer,
  i18nState,
  auth,
  subscription,
  insurance,
});

export const rootReducer = (state, action) => {
  return reducers(state, action);
};

import React from 'react';
import { Form } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import LaddaButton from 'react-ladda';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { InputField } from '../../helpers/form/render';
import { requiredField } from '../../helpers/form/validator';

const LoginForm = ({ handleSubmit }, context) => {
  const { loading, error, passwordResetSuccess } = useSelector((state) => state.auth);
  const history = useHistory();
  return (
    <div className="my-5">
      <Form onSubmit={handleSubmit}>
        <Field
          name="email"
          type="email"
          className="login-form-width"
          component={InputField}
          label={context.t('APP.REGISTER.EMAIL')}
        />

        <Field
          name="password"
          type="password"
          component={InputField}
          className="login-form-width"
          label={context.t('APP.REGISTER.PASSWORD')}
        />

        <div className="row flex-d justify-content-center align-items-center">
          <LaddaButton
            className="btn btn-primary btn-ladda mt-4 mb-1 mx-2 login-form-width"
            loading={loading}
          >
            {context.t('APP.LOGIN.BUTTON')}
          </LaddaButton>
        </div>

        <div className="row flex-d justify-content-center">
          <div className="column text-center">
            {!passwordResetSuccess ? (
              <p className="mt-2 primary pointer" onClick={() => history.push('/forgot-password')}>
                Återställ lösenord
              </p>
            ) : (
              <p className="mt-3 text-primary">Glömt lösenordets e-post har skickats</p>
            )}

            {error && <h7 class="text-danger text-center">{context.t(error)}</h7>}
          </div>
        </div>
      </Form>
    </div>
  );
};

LoginForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const loginFormValidator = (formProps) => {
  const errors = {};

  requiredField(formProps, errors, 'email', 'VALIDATION.EMAIL.ERROR_REQUIRED');
  requiredField(formProps, errors, 'password', 'VALIDATION.PASSWORD.ERROR_REQUIRED');

  return errors;
};

export default reduxForm({
  form: 'LoginForm',
  validate: loginFormValidator,
})(LoginForm);

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import DetailsForm from '../components/details/details-form';
import { insuranceDetails } from '../actions/insurance';
import { ACTIONS } from '../constants/insurance';

const DetailsPage = (_, context) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { done } = useSelector((state) => state.insurance);

  useEffect(() => {
    if (done) {
      history.push('/checkout');
      dispatch({ type: ACTIONS.INSURANCE_DETAILS_FAILURE, payload: null });
    }

    return () => {
      dispatch({ type: ACTIONS.INSURANCE_DETAILS_FAILURE });
    };
  }, [done, history, dispatch]);

  const onSubmitHandler = (data) => {
    data.bicycle_type_id = (data.bicycle_type === 'electric_bicycle') + 1;
    if (data.price) {
      data.price = parseInt(data.price);
    }
    if (data.purchase_date) {
      data.purchase_date = new Date(Date.parse(data.purchase_date)).toISOString();
    }

    dispatch(insuranceDetails(data));
  };

  return (
    <div className="container">
      <div className="col">
        <div className="row">
          <h1 className="my-5 font-weight-bold">{context.t('APP.DETAILS.TITLE')}</h1>

          <div className="col-12 col-md-8 mb-5 p-0">
            <div className="pt-3">
              <DetailsForm onSubmit={onSubmitHandler} initialValues={user} />
            </div>
          </div>
        </div>

        <div className="d-none d-sm-block space-5" />
      </div>
    </div>
  );
};

DetailsPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default DetailsPage;

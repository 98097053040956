import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, FormText } from 'reactstrap';

import RenderIf from '../../render-if';

class InputField extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const {
      className,
      input,
      label,
      placeholder,
      disabled,
      type,
      onKeyPress,
      onKeyUp,
      meta: { touched, error, invalid, warning },
    } = this.props;

    return (
      <FormGroup row className={`${touched && invalid ? 'has-error' : ''}`}>
        <div className="col d-flex flex-column justify-content-center align-items-center">
          <RenderIf condition={label !== undefined && label !== ''}>
            <Label className={`control-label pt-2 ${className}`}>{label}</Label>
          </RenderIf>
          <Input
            {...input}
            value={input.value}
            className={`${className} form-control${touched && invalid ? 'is-invalid' : ''}`}
            placeholder={placeholder}
            disabled={disabled}
            onKeyPress={onKeyPress}
            onKeyUp={onKeyUp}
            type={type}
          />
          <FormText color="danger" className={`${className} help-block}`}>
            {touched &&
              ((error && <span>{this.context.t(error)}</span>) ||
                (warning && <span>{this.context.t(warning)}</span>))}
          </FormText>
        </div>
      </FormGroup>
    );
  }
}

export default connect((state) => ({
  lang: state.i18nState.lang,
}))(InputField);

import { ACTIONS } from '../constants/insurance';

const INITIAL_STATE = {
  done: false,
  error: null,
  loading: false,
  ssf: false,
};

export const insurance = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.INSURANCE_DETAILS_START:
      return {
        ...state,
        loading: true,
        error: null,
        done: false,
        ssf: action.payload,
      };

    case ACTIONS.INSURANCE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        done: true,
      };

    case ACTIONS.INSURANCE_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        done: false,
      };

    default:
      return state;
  }
};

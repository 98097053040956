import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';

import RenderIf from '../../render-if';

class PriceInputField extends Component {
  static contextTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const {
      input,
      label,
      placeholder,
      disabled,
      type,
      onKeyPress,
      onKeyUp,
      meta: { touched, error, invalid, warning },
    } = this.props;

    return (
      <FormGroup row className={`${touched && invalid ? 'has-error' : ''}`}>
        <div className="col d-flex flex-column justify-content-center align-items-center">
          <RenderIf condition={label !== undefined && label !== ''}>
            <Label className="w-100 control-label pt-2">{label}</Label>
          </RenderIf>

          <div className="row flex-d w-100">
            <div className="form-control price-input-curr">SEK</div>
            <Input
              {...input}
              value={input.value}
              className={`form-control price-input ${touched && invalid ? 'is-invalid' : ''}`}
              placeholder={placeholder}
              disabled={disabled}
              onKeyPress={onKeyPress}
              onKeyUp={onKeyUp}
              type={type}
            />
          </div>
          {touched &&
            ((error && <span>{this.context.t(error)}</span>) ||
              (warning && <span>{this.context.t(warning)}</span>))}
        </div>
      </FormGroup>
    );
  }
}

export default connect((state) => ({
  lang: state.i18nState.lang,
}))(PriceInputField);

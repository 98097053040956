import * as AccountAPI from '../api/account';
import { ACTIONS, REGISTER_ERRORS, LOGIN_ERRORS } from '../constants/auth';
import { handleApiError } from '../helpers/helpers';

export const register = (data) => (dispatch) => {
  dispatch({ type: ACTIONS.REGISTER_START });

  AccountAPI.register(data)
    .then((response) => {
      dispatch({ type: ACTIONS.REGISTER_SUCCESS, payload: response.data.data });
    })
    .catch((err) => {
      const message = handleApiError(err, REGISTER_ERRORS);

      dispatch({ type: ACTIONS.REGISTER_FAILURE, payload: message });
    });
};

export const login = (data) => (dispatch) => {
  dispatch({ type: ACTIONS.LOGIN_START });

  AccountAPI.login(data)
    .then((response) => {
      if (response.data.data.user.user_type_id === 1) {
        dispatch({ type: ACTIONS.LOGIN_SUCCESS, payload: response.data.data });
      } else {
        dispatch({
          type: ACTIONS.LOGIN_FAILURE,
          payload: { cause: 'You are not authorized to login' },
        });
      }
    })
    .catch((err) => {
      const message = handleApiError(err, LOGIN_ERRORS);

      dispatch({ type: ACTIONS.LOGIN_FAILURE, payload: message });
    });
};

export const forgotPassword = (email, history) => (dispatch) => {
  dispatch({ type: ACTIONS.FORGOT_PASSWORD_START });
  return AccountAPI.forgotPassword(email)
    .then((response) => {
      if (response.data) {
        if (response.data.success) {
          dispatch({ type: ACTIONS.FORGOT_PASSWORD_SUCCESS });
          history.push('/login');
        } else {
          dispatch({ type: ACTIONS.FORGOT_PASSWORD_FAILURE });
        }
      } else {
        dispatch({ type: ACTIONS.FORGOT_PASSWORD_FAILURE });
      }
    })
    .catch((error) => {
      dispatch({ type: ACTIONS.FORGOT_PASSWORD_FAILURE });
    });
};

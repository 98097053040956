import { ACTIONS } from '../constants/auth';

const INITIAL_STATE = {
  token: typeof localStorage.getItem('token') === 'string' ? localStorage.getItem('token') : null,
  passwordResetSuccess: false,
  loggedIn: false,
  user: null,
  error: null,
  loading: false,
};

export const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.REGISTER_START:
      return {
        ...state,
        token: null,
        loggedIn: false,
        user: null,
        error: null,
        loading: true,
      };

    case ACTIONS.REGISTER_SUCCESS:
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('refresh_token', action.payload.refresh_token);

      return {
        ...state,
        token: action.payload.token,
        loggedIn: true,
        user: action.payload.user,
        error: null,
        loading: false,
      };

    case ACTIONS.REGISTER_FAILURE:
      return {
        ...state,
        token: null,
        loggedIn: false,
        user: null,
        error: action.payload,
        loading: false,
      };

    case ACTIONS.LOGIN_START:
      return {
        ...state,
        token: null,
        loggedIn: false,
        user: null,
        error: null,
        loading: true,
      };

    case ACTIONS.LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('refresh_token', action.payload.refresh_token);

      return {
        ...state,
        token: action.payload.token,
        loggedIn: true,
        user: action.payload.user,
        error: null,
        loading: false,
      };

    case ACTIONS.LOGIN_FAILURE:
      return {
        ...state,
        token: null,
        loggedIn: false,
        user: null,
        error: action.payload,
        loading: false,
      };

    case ACTIONS.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetSuccess: true,
      };

    default:
      return state;
  }
};

import React from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';

const Header = () => {
  return (
    <Navbar className="app-header">
      <NavbarBrand href="/register" />
    </Navbar>
  );
};

export default Header;

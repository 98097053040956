import axios from 'axios';

export const subscribe = (token, plan, promoCode) => {
  return axios({
    method: 'POST',
    url: '/subscriptions/',

    data: {
      subscription_plan: plan,
      stripe_token: token,
      promo_code: promoCode,
    },
  });
};

export const insurances = () => {
  return axios({
    method: 'GET',
    url: '/subscriptions/insurances',
  });
};

export const purchase = (token, oneOf) => {
  return axios({
    method: 'POST',
    url: '/subscriptions/one-time',

    data: {
      one_time_plan: oneOf,
      stripe_token: token,
      is_insurance_product: true,
    },
  });
};

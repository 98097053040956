import React from 'react';
import PropTypes from 'prop-types';

const ConfirmationPage = (props, context) => {
  return (
    <div className="vw-100">
      <div className="col d-flex flex-column">
        <div className="d-none d-sm-block space-5" />

        <div className="container">
          <div className="row d-flex flex-column justfy-content-center align-items-center py-5 px-4">
            <h1 className="font-weight-bold mb-5 text-center align-items-center">
              {context.t('APP.CONFIRMATION.TITLE')}
            </h1>
            <h4 className="text-center w-75">{context.t('APP.CONFIRMATION.DESC')}</h4>

            <a className="btn btn-primary btn-ladda mt-5 text-center" href={'https://mioo.cc'}>
              {context.t('APP.CONFIRMATION.BUTTON')}
            </a>
          </div>
        </div>

        <div className="d-none d-sm-block space-5" />
        <div className="border-top"></div>

        <div className="container d-flex align-items-center justify-content-center">
          <div className="column d-flex flex-column justify-content-center align-items-center py-5 px-4">
            <div className="d-none d-sm-block space-5" />
            <h1 className="font-weight-bold mb-5 text-center">{context.t('APP.CHECKOUT.TITLE')}</h1>

            <h4 className="text-center">{context.t('APP.CHECKOUT.DESC')}</h4>
            <div className="d-none d-sm-block space-5" />
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmationPage.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ConfirmationPage;
